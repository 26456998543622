.logo {
  width: 200px;
}
.login-left::before {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  content: "";
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  height: 85%;
}

.login-left::after {
  position: absolute;
  content: "OR";
  font-weight: 700 !important;
  font-size: 16px;
  background-color: #f9fafd;
  color: #90959f;
  right: -80px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  padding: 5px 20px;
}

.login-btn {
  background: #0ea4e7;
  box-shadow: inset 6px 6px 30px #009fe7, inset -10px -10px 30px #77d5ff !important;
  transition: 0.5s;
}
