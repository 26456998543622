.coverimg {
  background-image: url("../../images/user-cover.jpeg");
  background-size: cover;
  background-position: center center;
  height: 226px;

  border: 1px solid;
  position: relative;
}
.usermodal {
  width: 60%;
}
.usermodal .useravatar {
  width: 140px;
  height: 140px;
  bottom: -70px;
  margin-left: 90px;
}
.usermodal .item p {
  color: rgba(51, 51, 51, 0.6);
}
