/* .neu-box::placeholder {
  color: #7e7e7e;
  font-weight: 600;
}
.color-darkbule {
  color: #383874;
}

.border-blue {
  border: 2px solid #0ea4e7;
}
.border-grey {
  border: 2px solid #c4c4c4;
}
div.neu-box {
  color: #7e7e7e;
  transition: all 0.2s;
}

div.neu-box:hover {
  color: #383873;
} */

div.neu-box {
  background: #f9fafd;
  box-shadow: inset -5px -5px 30px rgba(242, 242, 242, 0.8),
    inset 5px 5px 10px rgba(232, 232, 232, 0.8);
  color: #7e7e7e;
  height: 76px;
  font-size: 16px;
  border-radius: 12px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 0.02em;
}

div.neu-icon {
  background: #ffffff;
  box-shadow: -5px -5px 20px #ffffff, 18px 18px 30px #e4e4e4,
    inset 18px 18px 30px #f3f3f3, inset -5px -5px 10px #ffffff;
  border-radius: 12px;
  width: 44px;
  height: 44px;
}

div.neu-box input {
  position: absolute;
  top: 0;

  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
}
