/* .table-header {
} */

/* .close-btn {
  opacity: 0;
} */

.table-header:hover .close-btn {
  opacity: 1;
}
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.listName::placeholder {
  color: #e1e1e0;
}

.properties .editable {
  color: #b8b8b6;
}

.properties .property > div {
  padding: 5px;
  transition: all 0.5s;
}

.properties .property > div:first-child {
  padding: 8px 14px;
  transition: all 0.5s;
}
.properties .property > div.editable:hover {
  cursor: pointer;
  background: rgba(55, 53, 47, 0.08);
}

.ant-menu-submenu-title {
  padding-left: 0px !important;
  border: none !important;
}

.ant-menu-submenu-selected {
  color: #000;
}

.ant-menu-item-selected::after {
  opacity: 0 !important;
  transform: scale(0);
}
.ant-menu-sub.ant-menu-inline {
  background: transparent;
}

.ant-menu-light .ant-menu-submenu-active {
  color: #000 !important;
}

.ant-menu-submenu-title {
  color: #000 !important;
  padding-left: 14px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  /* color: #fff !important; */
}
.deleteMenu {
  color: red !important;
}

.ant-menu-root.ant-menu-vertical {
  background-color: #fff;
  color: #000 !important;
}

.property-name-input {
  border: none !important;
}

.property-name-input-container {
  border: none !important;
}
.type-selector .ant-menu-item-group-title {
  font-size: 10px;
}

.type-selector .ant-menu-submenu-title {
  padding: 0;
  border: 1px solid #000;
}

.property-name-input-container {
  border: 1px solid #000;
  background-color: #e1e1e0 !important;
}

.property-name-input {
  height: 90%;
  color: #000;
}
.ant-dropdown {
  box-shadow: 0 10px 10px rgba(55, 53, 47, 0.08);
}

.datebox {
  height: 100px;
  position: relative;
  border: 1px solid rgb(233, 233, 231);
  cursor: initial;
}

.datebox .addicon {
  opacity: 0;
}

.datebox:hover .addicon {
  opacity: 1;
  cursor: pointer;
  transition: background-color 0.5s;
}
.taskoptions {
  opacity: 0;
}
.task:hover .taskoptions {
  opacity: 1;
}

.table-header {
  background-color: transparent !important;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-30 15:42:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-hor-center
 * ----------------------------------------

 
 */

.scale-in-hor-center {
  -webkit-animation: scale-in-hor-center 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-hor-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}
@keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-30 15:45:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
.fade-in-fwd {
  -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-19 15:41:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
