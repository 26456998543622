.badge.kwh {
  background: rgba(14, 164, 231, 0.15);
  color: #0ea4e7;
}

.badge.steam {
  background: rgba(100, 228, 128, 0.15);
  color: #47d266;
}

.building {
  width: 31%;
}

.ring-4 {
  border: 2px solid #0da4e7;
}

.cursor-not-allowed > * {
  cursor: not-allowed;
}
