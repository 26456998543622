.metercard {
  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 20px;
  padding: 25px 0;

  height: 360px;
  transition: all 0.2s ease-out;
  overflow: hidden;
}

.metercard .icon {
  background: #ffffff;
  /* box-shadow: 5px 5px 30px #efefef; */
  /* border-radius: 8px; */
  padding: 13px;
  margin-right: 15px;
}

.badge {
  padding: 8px 10px;
}

.metercard:not(.equipmentcard) > div:not(.chart) {
  padding: 0 25px;
}

.metercard .chart {
  transform: scale(1.07);
}

.metercard.equipmentcard .chart {
  transform: scale(1.2);
}

.metercard.equipmentcard {
  height: 462px;
  max-width: 457px;
  padding: 24px 10px;
}

.flip-out-hor-top {
  -webkit-animation: flip-out-hor-top 0.45s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: flip-out-hor-top 0.45s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-15 2:13:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-out-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    opacity: 0;
  }
}
@keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    opacity: 0;
  }
}

.flip-in-hor-bottom {
  -webkit-animation: flip-in-hor-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-15 2:15:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2021-10-15 2:16:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

.metercard button,
.equipmentcard button {
  background-color: #0da4e7;
}
.metercard:hover {
  box-shadow: 0px 10px 8px rgba(38, 38, 38, 0.2);

  transform: translateY(-10px) scale(1.01);
  /* border: 1px solid #cccccc; */
  background-color: white;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-11-23 13:10:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.metercard:hover button {
  animation: pulsate-fwd 1s infinite;
}
