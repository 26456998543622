.slide {
  text-align: center !important;
}
.slick-list {
  margin: 0 -7px !important;
}

.slick-slide > div {
  padding: 0 7px !important;
}
button.slick-arrow {
  z-index: 100;

  height: 100%;
}

button.slick-arrow.slick-prev {
  width: 100px;
}
button.slick-arrow.slick-next {
  right: -3%;
  width: 100px;
}
button.slick-arrow.slick-prev::after,
button.slick-arrow.slick-next::after {
  content: "";
  display: block;
  position: absolute;
  background-image: url("https://static.thenounproject.com/png/1916331-200.png");
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  top: calc(50% - 50px);
  right: 22%;
  background-size: 100%;
  border-radius: 50%;
  box-shadow: 11px -11px 22px #d0d0d0, -11px 11px 22px #f0f0f0;
}

button.slick-arrow.slick-prev::after {
  background-image: url("https://static.thenounproject.com/png/1916329-200.png");
}

ul.slick-dots {
  bottom: -40px;
}
ul.slick-dots li {
  transform: scale(2);
}
ul.slick-dots li::after {
  background-color: #fff !important;
}
ul.slick-dots li.slick-active {
}

.slick-track {
  display: flex !important;
  align-items: center;
}

.slick-slide {
  height: inherit !important;
}

.mapbox-row {
  /* height: 450px; */
}
.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-10-14 13:46:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck-top
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2021-10-14 13:50:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.flip-horizontal-bottom {
  -webkit-animation: flip-horizontal-bottom 0.4s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}

@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}
