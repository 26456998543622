.panelToggleButtons {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 160%;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.panelToggleButtons.tab-active {
  border-bottom: 2px solid #383874;
  color: #383874;
}

.details-box {
  background: #ffffff;
  border: 1px solid #e2e9ea;
  border-radius: 10px;
}
.details-box h3 {
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.15em;
}
