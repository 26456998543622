.novo-logo-img {
  width: 190px;
  height: 23px;
}

.univ-logo-img {
  width: 37px;
}

.avatar {
  width: 44px;
  height: 44px;
}
