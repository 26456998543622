.selection {
  color: #000;
}

.searchbar {
  color: rgb(123, 121, 125);
}

.card {
  border: 2px solid #e2e9ea;
  box-shadow: 5px 10px 50px rgba(0, 67, 96, 0.15);
  border-radius: 15px;
}

.home-icon {
  color: #0da4e7;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 1s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ant-picker-dropdown {
  top: 0;
  left: 0;
}
.ant-picker.datepicker {
  visibility: hidden;
  height: 0px !important;
}
.bounce-left {
  -webkit-animation: bounce-left 1.5s infinite both;
  animation: bounce-left 1.5s infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-9-7 12:16:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-left {
  0% {
    -webkit-transform: translateX(-48px);
    transform: translateX(-48px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateX(-26px);
    transform: translateX(-26px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(-13px);
    transform: translateX(-13px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateX(-6.5px);
    transform: translateX(-6.5px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-left {
  0% {
    -webkit-transform: translateX(-48px);
    transform: translateX(-48px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateX(-26px);
    transform: translateX(-26px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(-13px);
    transform: translateX(-13px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateX(-6.5px);
    transform: translateX(-6.5px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.timepopover-panel {
  padding: 0px;
}

.add-line::after {
  content: "";
  border: 1px solid #e2e9ea;
  position: absolute;
  top: 0;
  right: 30px;
  height: 80%;
  transform: translateY(15%);
}

td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
  color: #000;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid rgba(15, 164, 231, 0.5);
  background-color: rgba(15, 164, 231, 0.5);

  /* z-index: -10; */
}

.ant-picker-body {
  padding: 18px !important;
}
.ant-picker-month-panel
  .ant-picker-content
  tbody
  > tr:not(.ant-picker-content tbody > tr:last-child) {
  border-bottom: 1px solid #e2e9ea;
}

.ant-picker-year-panel
  .ant-picker-content
  tbody
  > tr:not(.ant-picker-content tbody > tr:last-child) {
  border-bottom: 1px solid #e2e9ea;
}

.ant-picker-month-panel .datepicker-month .ant-picker-content th,
.ant-picker-content td:first-child {
  border-right: 1px solid #e2e9ea;
}
.ant-picker-month-panel .ant-picker-content th,
.ant-picker-content td:nth-child(2) {
  border-right: 1px solid #e2e9ea;
}

.overlay {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}

.count-badge {
  background-color: #0fa4e7;
}

.count-badge-loading {
  background-color: transparent;
}

.metersSection.addLine::after {
  content: "";
  border: 1px solid #e7e9ee;
  position: absolute;
  right: -30px;
  top: 20px;
  height: 100%;
}

/* // NEW DESIGN CSS */

.searchbar {
  filter: drop-shadow(5px 5px 15px #efefef);
}

.home-button {
  box-shadow: 5px 5px 30px 0px rgba(239, 239, 239, 1);

  box-shadow: 5px 5px 30px #efefef;
}

.searchbar {
  color: #7e7e7e;
}

.selection {
  color: #000;
}

.selection.meter {
  border: 2px solid #0ea4e7;
  border-radius: 8px;
}

.selection.site {
  border: 2px solid #64e480;
  border-radius: 8px;
}

.selection.time {
  border: 2px solid #808080;
  border-radius: 8px;
}

.selection .popover-btn {
  font-size: 12px;
}

.metercard.inside-searchbar {
  padding: 10px 0;
  /* width: auto !important; */
  height: auto !important;
  min-width: 300px;
  width: 30%;
  margin: 10px 10px;
}
.metercard.inside-searchbar p {
  max-height: 50px !important;
  overflow: hidden;
}

.tab.tab-active {
  border-bottom: 2px solid #0fa4e7;
  color: #0fa4e7;
}

.site-groups .img {
  text-align: center;
  padding: 10px 0;
}
.site-groups .count {
  background-color: #0da4e7;
}
