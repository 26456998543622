.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* Accordion styles */
.accordion input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.accordion .tabs {
  border-radius: 8px;
  overflow: hidden;
}
.accordion .tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.accordion .tab-label {
  display: flex;

  padding: 1em 0.5em;
  background: #fff;

  cursor: pointer;
  color: #000;
  /* Icon */
}
.accordion .tab-label:hover {
  background: #f9fafb;
}
.accordion .tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  margin-left: auto;
}
.accordion .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}
.accordion .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.accordion .tab-close:hover {
  background: #f9fafb;
}
.accordion input:checked + .tab-label {
  background: #f9fafb;
}
.accordion input:checked + .tab-label::after {
  transform: rotate(90deg);
}
.accordion input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

.accordion .tab.command .tab-label:hover,
.accordion input:checked + .tab-label.label-command {
  background-color: #f8d5e7;
}
.accordion .tab.setpoint .tab-label:hover,
.accordion input:checked + .tab-label.label-setpoint {
  background-color: #d5e8e3;
}
.accordion .tab.sensor .tab-label:hover,
.accordion input:checked + .tab-label.label-sensor {
  background-color: #d3e6f9;
}

.sensor-badge {
  background-color: #d3e6f9;
}
.command-badge {
  background-color: #f8d5e7;
}
.setpoint-badge {
  background-color: #d5e8e3;
}
