.meters > div {
  box-shadow: inset 2px 2px 5px hsl(0deg 0% 83% / 80%);
}

.meters .dot {
  width: 10px;
  height: 10px !important;
  background-color: #ffba69;
  margin-right: 10px;
  border-radius: 100%;
}
.breakdown-chart {
  height: 500px;
}

.utility-card {
  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 16.9967px;
  height: 146.6px;
  margin: 0 10px;
}

.utility-cards .utility-card:first-child {
  margin-left: 0px;
}

.utility-cards .utility-card:last-child {
  margin-right: 0px;
}

.utility-card h1 {
  font-size: 32px;
  color: #666666;
}

.utility-card .icon-wrapper {
  width: 60px;
  height: 60px;
  background-color: rgba(0, 174, 239, 0.05);
  border-radius: 12.7475px;
}

.utility-chart-wrapper {
  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 21.2458px;
  padding: 28px;
}

.offPeakBg {
  background-color: #ffba69;
}

.onPeakBg {
  background-color: #00aeef;
}

.utility-card.v2 p {
  color: #7e7e7e;
  font-size: 24px;
}
.utility-card.v2 {
  height: 168px;
  max-width: 90%;
}

.utility-card.v2 h1 {
  color: #383874;
  font-size: 55px;
  font-weight: 600;
}

.spacer-top {
  margin-top: 30px;
}

.saveDataBtn,
.nextMetricBtn {
  height: 56px;
  width: 186px;
  left: 1170px;
  color: #fff;
  background-color: #00aeef;
  font-size: 16px;
  border-radius: 12px;
  text-align: center;
}

.nextMetricBtn {
  background-color: #fff;
  border: 3px solid #00aeef;
  color: #00aeef;
  font-weight: bold;
}

.radio-btn {
  width: 22px;
  height: 22px;
  padding: 3px;
  border: 2px solid #00aeef;
}

.radio-btn .active-radio-btn {
  background-color: #00aeef;
}

.animating-layover {
  left: 100%;
  top: 50%;
  border-radius: 100%;
  transform: scale(0.2);
  transition: all 0.5s ease-out;
}
.animating-layover.animate {
  transform: scale(1.5);
  transform-origin: bottom right;
  top: 0;
  left: 0;
  border-radius: 0%;
}

.active_entry {
  box-shadow: 0px -2px 10px rgba(38, 38, 38, 0.2);

  transform: translateY(-2px) scale(1.01);
  /* border: 1px solid #cccccc; */
  background-color: white;
}
