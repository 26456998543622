.maps-card {
  background: #ffffff;
  box-shadow: 5px 5px 15px rgba(239, 239, 239, 0.6);
  border-radius: 10px;
}

.map-marker {
  background-color: #f3f3f3;
  min-width: 230px;
  box-shadow: inset -10px -10px 30px rgb(246 246 246 / 80%),
    inset 10px 10px 30px rgb(237 237 237 / 80%);
  position: relative;
}
.map-marker::before {
  content: "";
  position: absolute;
  left: -16%;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(90deg);
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-top: 30px solid #f3f3f3;
}

.map-marker .loc-icon {
  left: -18%;
  top: 38%;
}
.middle {
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}

.map-wrapper {
  background: #ffffff;
  box-shadow: -20.337px -20.337px 20.337px #ffffff,
    10.1685px 10.1685px 20.337px rgba(228, 228, 228, 0.7),
    inset 10.1685px 10.1685px 20.337px rgba(243, 243, 243, 0.7),
    inset -10.1685px -10.1685px 20.337px rgba(255, 255, 255, 0.7);
  border-radius: 24.4044px;
}

.info .label {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.maps-card .info .icon {
  margin-right: 20px;
}
.maps-card.info-card {
  top: 23px;
  left: 23px;
  height: calc(100% - (23px + 23px));
}

.maps-card.weather-card {
  right: 23px;
  top: 23px;
  height: 200px;
  width: 300px;
}

.maps-card.weather-card h1 {
  font-size: 16px;
}

.weatherchart {
  transform: scale(1.05);
}

.maps-card.weather-card h1.weather-degree {
  font-size: 20.337px;
  line-height: 25px;
  letter-spacing: 0.02em;
}
