@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --primary-text-color: #383874;
  --secondary-text-color: rgba(87, 87, 137, 0.6);
}

.primary-text-color {
  color: var(--primary-text-color);
}

.secondary-text-color {
  color: var(--secondary-text-color);
}

.primary-bg-color {
  background-color: var(--primary-text-color);
}
.secondary-bg-color {
  background-color: var(--secondary-text-color);
}

html {
  font-family: "Montserrat", "sans-serif";
}

body {
  font-family: inherit !important;
  background-color: #f9fafd;
  /* background-color: var(--primary-text-color); */
}
* {
  box-sizing: border-box;
  font-family: inherit;
}

.neu-icon {
  box-shadow: -18px -18px 30px #ffffff, 18px 18px 30px #dbdbdb,
    inset 18px 18px 30px #f3f3f3, inset -18px -18px 30px #ffffff;
}
.neu-box {
  box-shadow: inset -10px -10px 30px rgb(246 246 246 / 80%),
    inset 10px 10px 30px rgb(237 237 237 / 80%);
}
.neu-icon-in {
  box-shadow: inset 2px 2px 7px #e2e9f3, inset -2px -2px 7px #fff;
}

.bg-blue {
  background-color: #0ea4e7;
}
.dropdown-min-width {
  min-width: 150px;
}

.fade-in-bck {
  -webkit-animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-14 14:5:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bck
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
