.toggle {
  background: #efefef;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 700;
  height: 50px;
  padding: 10xp 0;
  z-index: -2;
}

/* .toggle > div.cost {
  width: 35%;
}
.toggle > div.consumptions {
  width: 65%;
} */

.toggle .slider {
  height: 84%;
  top: 4px;
  left: 3px;
  border-radius: 10px;
  background-color: #fff;
  z-index: -1;
  transition: right left 0.5s;
}

.toggle .slider.right {
  left: unset;
  right: 3px !important;
}
.toggle .slider.left {
  right: unset;
  left: 3px !important;
}
