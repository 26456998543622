@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");

.display-slider .slide {
  width: 90% !important;
  margin: 0 auto !important;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.display-slider {
  width: 100%;
}
.vh-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.relative.display {
  height: 100vh !important;
}

.display-slider h1 {
  font-size: 40px;
  margin: 0;
}

.min-kwhs {
  position: absolute;
  left: 70px;
  top: 55.55%;
  transform: translateY(-50%);

  width: 300px;
  background-color: #fff;
  text-align: left;
  z-index: 10;
  display: flex;
  padding: 25px;
  align-items: center;
  border-radius: 20px;
}
.min-kwhs h2 {
  margin: 0;
  margin-right: 5px;
  overflow: hidden;
  width: 130px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}
.kwh-visual {
  width: 10px;
  height: 10px;
  background-color: #383874;
  margin: 0 1px;
}
/* 
.min-kwhs .kwh-visual {
  width: 10px;
  background-color: #383874;
  height: 10px;
  margin-left: 3px;
} */
/* 
.min.min-kwhs .kwh-visual {
  height: 10px;
  width: 10px;
  background-color: #383874;
} */

.ant-progress-text {
  font-size: 20px;
}
